<template>
  <div>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import '~@/assets/styles/fonts.css';
@import '~@/assets/styles/reset.css';

body.p1 {
  background: #480CA8;
}
body.p2 {
  background: #F8B9D3;
}
body.p3 {
  background: #3C50B5;
}
body.p4 {
  background: #4A4A4D;
}
body.p5 {
  background: #1D1D1E;
}
#app {
  font-family: 'nb_grotesk_pro55m_stencil', Helvetica, Arial, sans-serif;
  color: #010101;
  overflow-x: hidden;
  max-width: 430px;
  width: 100%;
  margin: 0 auto;
}

@media all and (min-width: 431px) {
  #app {
    overflow-x: visible;
  }
}
</style>
