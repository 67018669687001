<template>
  <div class="wrapper">
    <div class="inner">
      <div class="timeline">
        <div><span id="span1"></span></div>
        <div><span id="span2"></span></div>
        <div><span id="span3"></span></div>
        <div><span id="span4"></span></div>
        <div><span id="span5"></span></div>
      </div>
      <div class="screen -active -s1" id="s1">
        <div class="back"></div>
        <div class="title"><img alt="REDESIGN CONNECTION" src="../assets/images/redesign.svg" /></div>
        <div class="form"></div>
      </div>
      <div class="screen -s2" id="s2">
        <div class="back"></div>
        <div class="title"><img alt="ENGAGE WITH NFT" src="../assets/images/engage.svg" /></div>
        <div class="form"></div>
      </div>
      <div class="screen -s3" id="s3">
        <div class="back"></div>
        <div class="title"><img alt="PLAY AND EARN" src="../assets/images/play.svg" /></div>
        <div class="form"></div>
      </div>
      <div class="screen -s4" id="s4">
        <div class="back"></div>
        <div class="title"><img alt="COMMUNITY" src="../assets/images/community.svg" /></div>
        <div class="form"></div>
      </div>
      <div class="screen -s5" id="s5">
        <div class="back"></div>
        <div class="title"><img alt="ABOUT" src="../assets/images/about.svg" /></div>
      </div>
      <a class="button" href="#">Join Waitlist</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}

let count = 0
let time = Date.now()+2000
let interval = 5000

setTimeout(() => {
  document.getElementById("s1").onclick = function() {next()};
  document.getElementById("s2").onclick = function() {next()};
  document.getElementById("s3").onclick = function() {next()};
  document.getElementById("s4").onclick = function() {next()};
  document.getElementById("s5").onclick = function() {next()};

  setInterval(ticker,25)
}, 2000);

function ticker() {
  let el = document.getElementById("span"+(count+1))
  let now = Date.now()
  let diff = now - time

  if (diff > interval) {
    el.style.width = "100%";

    document.getElementById("s"+(count+1)).classList.remove("-active");
    document.getElementById("body").classList.remove("p"+(count+1));
    time = Date.now()
    count++

    if (count > 4) {
      reset()
      count = 0
    }

    document.getElementById("s"+(count+1)).classList.add("-active");
    document.getElementById("body").classList.add("p"+(count+1));
  } else {
    el.style.width = ((diff/interval)*100)+"%";
  }
}

function reset() {
  document.getElementById("span1").style.width = "0%";
  document.getElementById("span2").style.width = "0%";
  document.getElementById("span3").style.width = "0%";
  document.getElementById("span4").style.width = "0%";
  document.getElementById("span5").style.width = "0%";
}

function next() {
  document.getElementById("span"+(count+1)).style.width = "100%";

  document.getElementById("s"+(count+1)).classList.remove("-active");
  document.getElementById("body").classList.remove("p"+(count+1));
  time = Date.now()
  count++

  if (count > 4) {
    reset()
    count = 0
  }

  document.getElementById("s"+(count+1)).classList.add("-active");
  document.getElementById("body").classList.add("p"+(count+1));
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 15px 0 0;

  .inner {
    padding: 0 20px 192px;

    .timeline {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 0 47px;

      div {
        width: calc(20% - 3px);
        height: 2px;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.4);
        
        span {
          height: 2px;
          display: block;
          background: #fff;
          border-radius: 2px;
          width: 0;
        }
      }
    }
    .screen {
      opacity: 0;
      height: 0;
      position: relative;
      padding: 0;
      overflow: hidden;

      .title {
        border-radius: 8px;
        margin: 0 auto;
        text-align: center;
        position: absolute;
        top: 0;
        left: 4px;
        width: calc(100% - 8px);
        height: 45px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .-s1 {
      .back {
        background: url(../assets/images/back1.png) no-repeat scroll top center;
        background-size: 460.86px 540.63px;
        width: 461px; 
        height: 541px;
        border-radius: 44px;
        transform: rotate(21deg);
        margin: 0px 0 0 -61px;
      }
      .form {
        background: url(../assets/images/form1.png) no-repeat scroll top center;
        width: 230px;
        height: 239px;
        background-size: 100%;
        position: absolute;
        bottom: -60px;
        left: calc(50% - 115px);
      }
    }
    .-s2 {
      .back {
        background: url(../assets/images/back2.png) no-repeat scroll top center;
        background-size: 100%;
        width: 363px; 
        height: 562px;
        border-radius: 44px;
        margin: 50px auto 0;
      }
      .form {
        background: url(../assets/images/form2.png) no-repeat scroll top center;
        width: 240px;
        height: 163px;
        background-size: 100%;
        position: absolute;
        bottom: -35px;
        left: 0px;
      }
    }
    .-s3 {
      .back {
        background: url(../assets/images/back3.png) no-repeat scroll top center;
        background-size: 100%;
        width: 339px;
        height: 604px;
        border-radius: 44px;
        margin: 6px auto 0;
      }
      .form {
        background: url(../assets/images/form3.png) no-repeat scroll top center;
        width: 245px;
        height: 142px;
        background-size: 100%;
        position: absolute;
        bottom: -25px;
        left: calc(50% - 122px);
      }
    }
    .-s4 {
      .back {
        background: url(../assets/images/back4.png) no-repeat scroll top center;
        background-size: 100%;
        width: 376px;
        height: 562px;
        border-radius: 44px;
        margin: 50px auto 0;
      }
      .form {
        background: url(../assets/images/form4.png) no-repeat scroll top center;
        width: 205px;
        height: 301px;
        background-size: 100%;
        position: absolute;
        bottom: -82px;
        right: 7px;
      }
    }
    .-s5 {
      .back {
        background: url(../assets/images/back5.png) no-repeat scroll top center;
        background-size: 100%;
        width: 267px;
        height: 427px;
        border-radius: 30px;
        margin: 50px auto 0;
      }
    }
    .-active {
      opacity: 1;
      height: auto;
      padding: 57px 0 0 0;
      overflow: visible;
    }
    .button {
      font-family: "sf_pro_text";
      background: #fff;
      color: #000000;
      text-decoration: none;
      width: 160px;
      display: block;
      height: 55px;
      text-align: center;
      font-size: 20px;
      line-height: 55px;
      border-radius: 50px;
      position: fixed;
      bottom: 15px;
      left: calc(50% - 80px);
    }
  }
}
</style>
